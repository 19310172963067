import * as Sentry from '@sentry/remix'
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { hydrateRoot } from 'react-dom/client'
import { startTransition, StrictMode, useEffect } from 'react'
import { RemixBrowser, useLocation, useMatches } from '@remix-run/react'

import CustomConfigProvider from '~/components/ConfigProvider'

Sentry.init({
  dsn: 'https://e26398d48b141719be275f0d093ca021@o4507859474186240.ingest.de.sentry.io/4507870837473360',
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,

  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.replayIntegration(),
  ],
})

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <CustomConfigProvider>
        <RemixBrowser />
      </CustomConfigProvider>
    </StrictMode>
  )
})
